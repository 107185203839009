import { IAppService, IStateConfig, IFxpAppContext, IServiceEndPoints, IRouteInfo, IPartnerBundle } from '@fxp/fxpservices';
import { appSettings } from './environments/common/appsettingsGenerator';
import { PartnerAppRegistrationService } from '@fxp/fxpservices';

export class SampleApplication_routes implements IAppService {

    getRoutes(fxpContext: IFxpAppContext): IRouteInfo {
        const gceCCESelfServeRoute: IStateConfig = {
        name: 'gcecceselfserve',
        url: '/gcecceselfserve',

generatedBundle: 'aeb2930ca808-GCECCESelfServeModule-Bundle',
        data: {
                headerName: 'GCE CCE Self-Serve',
                breadcrumbText: 'GCE CCE Self-Serve',
                pageTitle: 'GCE CCE Self-Serve'
             },
             
        };
        const routeInfo: IRouteInfo = {
            sharedBundles: [],
            routes: [gceCCESelfServeRoute]
        }
    
        return routeInfo;
    }

    getServiceEndPoints(): Array<IServiceEndPoints> {

        return appSettings().serviceEndPoints;
    }

    getBundles(): IPartnerBundle[] {
        const baseUrl = appSettings().cdnBaseUrl;
        const bundle: IPartnerBundle = {
          name: 'SampleApp-Bundle', //TODO: Please use the format {appname}-{bundleName} to ensure the name of the bundle is always unique. 
          files: [
            `${baseUrl}/vendor.bundle.js`,
            `${baseUrl}/styles.bundle.js`,
            `${baseUrl}/main.bundle.js`,
          ],
          sequentialLoading: true,
        };

        return [bundle];
      }

 public getGeneratedBundles(){
 let currentScriptUrl = document.currentScript['src']; 
let baseUrl = currentScriptUrl.substring(0, currentScriptUrl.lastIndexOf('/')); 
 return [
{
                name: 'aeb2930ca808-GCECCESelfServeModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/GCECCESelfServeModule.vendor.bundle.js',
                    baseUrl + '/GCECCESelfServeModule.bundle.js'
                ],
                sequentialLoading: true           
            },

]
 
}
}

PartnerAppRegistrationService.registerLazyLoadedApp(SampleApplication_routes, 'SampleApplication');
